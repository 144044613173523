import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'

class AdhesionIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [author] = get(this, 'props.data.allContentfulAdhesionPage.edges')

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '1.3em'
                }}>
                <div style={{marginBottom: "50px"}}>{author.node.punchline.punchline}</div>
                <a href="/adhesion_lzj.pdf" target="_blank" class="btn"><i class="fa fa-download"></i> Télécharger</a>
                <form
                  class="form"
                  name="Adhésion"
                  method="post"
                  action="/success"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  netlify
                >
                  <input type="hidden" name="bot-field" />
                  <div>
                    <input class="input" type="text" htmlFor="name" placeholder="Nom *" name="name" id="name" required />
                  </div>
                  <div>
                    <input class="input" type="text" htmlFor="firstName" placeholder="Prénom *" name="firstName" id="firstName" required />
                  </div>
                  <div>
                    <textarea class="textarea" type="text" htmlFor="address" placeholder="Adresse *" name="address" id="address" required />
                  </div>
                  <div>
                    <input class="input" type="email" htmlFor="email" placeholder="Email *" name="email" id="email" required />
                  </div>
                  <div>
                    <input class="input" type="text" htmlFor="phone" placeholder="Téléphone *" name="phone" id="phone" required />
                  </div>
                  <div>
                    <input type="submit" value="Envoyer" id="submitButton" />
                  </div>
                </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AdhesionIndex

export const pageQuery = graphql`
  query AdhesionQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAdhesionPage {
      edges {
        node {
          title
          shortBio {
            shortBio
          }
          punchline {
            punchline
          }
          heroImage: headerImage {
            fluid(
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
